@font-face{
    font-family: clean;
    src: url("/fonts/SEA_GARDENS.ttf");
}
@font-face{
    font-family: Goth;
    src: url("/fonts/Gotham-Light.otf");
}
@font-face{
    font-family: GothBlack;
    src: url("/fonts/Gotham-Bold.otf");
}
@font-face{
    font-family: Bold;
    src: url("/fonts/Gotham-Bold.otf");
}
@font-face{
    font-family: spart;
    src: url("/fonts/LeagueSpartan-Bold.otf");
}
@font-face{
    font-family: rom;
    src: url("/fonts/Romande.ttf");
}

.nav-heading{
    font-family: Goth;
    font-weight: 200;
    text-transform: uppercase;
    
}



.navbar-collapse .collapse .show{
  background: #561c54;
}
/* .nav-link{
  background: #561c54;
} */

.navbar-toggler button{
  color: #fff !important;
}
.nav-heading a{
  background:
     linear-gradient(
       to bottom, rgba(86, 28, 84, 1) 0%,
       rgba(86, 28, 84, 1) 100%
     );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 4px;
  color: #000;
  text-decoration: none;
  transition: background-size .2s;
}

/* .navbar-collapse .collapse .show{
  background:
  linear-gradient(
    to bottom, rgba(86, 28, 84, 1) 0%,
    rgba(86, 28, 84, 1) 100%
  );
} */

.rsvp-heading{
  font-family: rom;
  font-size: 0.95rem;
  /* display: block; */
}

.nav-heading a:hover {
  background-size: 4px 50px;
}

@media (max-width: 990px) {
  nav.navbar {
    background: rgba(86, 28, 84, 1);
  }
  nav.navbar .nav-link:hover{
    background: #fff;
    color: rgba(86, 28, 84, 1) !important;
  }
}

.nav-link{
    color: #fff;
}

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 100%;
  margin: 1rem;
  position: relative;
  width: 600px;
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

@media screen and (max-width: 700px){
  .container {
    flex-direction: column;
  }
  .video{
    width: 100%;
  }
  .vidContainer{
    width: 100%;
  }
  .w-100{
    height: 50vh;
    margin-top: 80px;
  }
  .map{
    width: 400px;
    height: 250px;
  }
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 51px);
  background-color: rgba(45, 13, 42, 1);
    /* filter: blur(10px); */
}

.mr-auto > a{
    color: #fff !important;
}

.nav-heading .navbar .navbar-expand-lg .navbar-dark{
  background:black;
}


.carousel-control-next-icon{
    width: 61px !important;
    height: 62px !important;
}

.carousel-control-prev-icon {
width: 61px !important;
height: 62px !important;
}

a { color: black; text-decoration: none; }

.arrow {
  text-align: center;
  margin: 5px 0 0 0;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* SPINNER */

.loader {
  color: #561c54;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
