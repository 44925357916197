body{
  font-family: 'Roboto'
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#background{
  /* width: 60%; */
  /* position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; */
  height: calc(100vh - 50px);
  /* background-position: center; 
  background-size: cover; 
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%; 

}

#john{
  z-index: 300;
}
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; }

body, html {
  height: 100%;
  width: 100%; }

h1 {
  font-size: 9em;
  margin: 0.67em 0;
  color: #353535;
  /* position: fixed; */ }

h2 {
  font-size: 15px !important;
  font-size: 15px;
  color: #353535 !important;
  text-transform: uppercase;
  font-weight: bold; }

h2::after {
  content: " ";
  border: solid 1px #ff0d00;
  display: block;
  width: 65px;
  margin: 20px auto; }

.holder {
  margin: 0;
  padding: 0;
  overflow: hidden; }

p {
  line-height: 28px; }

.row {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  margin-right: 15%; }

.rownav {
  position: fixed; }

.col .s1 {
  width: 200px; }



  .scrollto {
    padding: 150px 0px 0px 0px;
    display: block;
    color: #FFF;
    text-decoration: none;
    width: 200px;
    margin: 0 auto;
    border-bottom: none; }
  
  .scrollto--arrow {
    padding: 10px; }
  
  .scrollto--arrow img {
    animation: arrowbounce 1s alternate infinite;
    -moz-animation: arrowbounce 1s alternate infinite;
    /* Firefox */
    -webkit-animation: arrowbounce 1s alternate infinite;
    /* Safari and Chrome */
    -o-animation: arrowbounce 1s alternate infinite;
    /* Opera */ }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp; }
  
  .col-md-6.col-md-offset-3.text-center {
    margin-top: 20%;
    margin-left: -50%; }
  